import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18next';
import { Provider } from 'react-redux';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Correct import statement for thunk
import { persistStore, persistReducer } from 'redux-persist'; 
import storage from 'redux-persist/lib/storage';
import languageReducer from './store/reducer/language/language';
import menuReducer from './store/reducer/menu/menu';
import cartReducer from './store/reducer/cart/cart';
import { PersistGate } from 'redux-persist/integration/react';

document.body.style = 'background: #fafafa;';

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
    language: languageReducer,
    menu: menuReducer,
    cart: cartReducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['cart']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhances(applyMiddleware(thunk)))


const persistor = persistStore(store);

const app = (
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      {app}
    </Suspense>
  </React.StrictMode>
);
