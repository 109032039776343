import "../Styles/MainPage.css";
import Slider from "react-slick";
import FoodItem from "../Components/FoodItem";
import logo from "../Images/MainLogo.svg";
import BottomNav from "../Components/BottomNav";
import { AiOutlineGlobal } from "react-icons/ai";
import { useState, useEffect, useRef } from "react";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getProducts,
  setMenuType,
} from "../store/action/menu/menu";
import { setLanguage } from "../store/action/language/language";

function MainPage() {
  const search = window.location.search;
  const menuState = useSelector((state) => state.menu);
  const [menuVisible, setMenuVisible] = useState(false);

  const params = new URLSearchParams(search);
  const [categoryLoad, setCategoryLoad] = useState(false);
  const [prodId, setProdId] = useState(menuState.menuType.id);
  const topRef = useRef();
  const dispatch = useDispatch();
  const languageState = useSelector((state) => state.language);
  const { t, i18n } = useTranslation();
  const [type, setType] = useState(menuState.menuType);
  const [branchName, setBranchName] = useState();

  useEffect(() => {
   
      dispatch(getCategories());
    
  }, [
    dispatch,
    menuState.categories.length,
    languageState.lang,
    i18n.language,
  ]);

  const changeLanguage = (language, direction) => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language, direction));
    document.getElementById("root").style.direction = direction;
  };

  useEffect(() => {
    
    const branchId = JSON.parse(localStorage.getItem("branchId"));
    if (prodId !== null) {
      dispatch(getProducts(prodId, null, branchId));
      setCategoryLoad(false);
    }
  }, [dispatch, prodId, languageState.lang]);

  useEffect(() => {
    setBranchName(JSON.parse(window.localStorage.getItem("branchName")));
    console.log(branchName);
  }, []);

  // Function to handle category changes
  function handleSet(string, number) {
    
    if (!(type.value === string)) {
      setCategoryLoad(true);
      setType({ value: string, id: number });
      dispatch(setMenuType({ value: string, id: number }));
      localStorage.setItem(
        "activCateg",
        JSON.stringify({ value: string, id: number })
      );
      setProdId(number);
    }
  }
  useEffect(() => {
    if (menuState.categories.length == 0) {
      dispatch(getCategories());
    }
    
    document.title =
      i18n.language === "ar" ? "مطاعم الرومانسية" : "Al Romansiah Restaurant";
  }, [dispatch]);
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLanguageChange = (lang, dir) => {
    changeLanguage(lang, dir);
    setMenuVisible(false);
  };
  const menuStyle = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    position: "absolute",
    top: "40%",
    left: languageState.lang == "ar" || languageState.lang == "ur" ? 80 : 0,
    zIndex: 1000,
  };

  const buttonStyle = {
    border: "none",
    backgroundColor: "#fff",
    cursor: "pointer",
    padding: "10px 20px",
    textAlign: "left",
    width: "100%",
    marginBottom: "5px",
  };

  const lastButtonStyle = {
    ...buttonStyle,
    marginBottom: 0,
  };

  return (
    <div style={{'overflow': 'hidden'}} className="MainMenu ">
      {menuState.loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={menuState.loading} size={30} />
        </div>
      ) : (
        <div>
          <div className="container-fluid">
          <div ref={topRef} className="mpNav">
              <div style={{'display':'flex', margin: "0",   'position': 'relative'}} className="langSwitch">
                <img src={logo} alt="logo" />
                <div style={{ display: "inline-block" }}>
                <AiOutlineGlobal className="globe" size={25} />

                <button onClick={toggleMenu} className="langBtn">
                {languageState.lang.toUpperCase()}
              </button>
              {menuVisible && (
              <div style={menuStyle}>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("en", "rtl")}
                >
                  EN
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("ar", "ltr")}
                >
                  AR
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("id", "rtl")}
                >
                  ID
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("ur", "ltr")}
                >
                  UR
                </button>
              </div>
            )}
                  {/* <p>{branchName.arName ||branchName.enName &&  languageState.lang=="ar"?branchName.arName:branchName.enName }</p> */}
                </div>
              </div>
              <p
                  style={
                   {'textAlign':'center', 'color':'#e47100'}
                  }
                  className="mpP-2"
                >
                
                </p>
              <div>
                
                <p
                  style={
                    languageState.lang == "en" || languageState.lang == "id"
                      ? { textAlign: "left" }
                      : { textAlign: "right" }
                  }
                  className="mpP-1"
                >
                  {t("home.title1")}{" "}
                </p>
                <p
                  style={
                    languageState.lang == "en" || languageState.lang == "id"
                      ? { textAlign: "left" }
                      : { textAlign: "right" }
                  }
                  className="mpP-2"
                >
                  {t("home.title2")}
                </p>

               
              </div>
              
            </div>
          </div>

          <div
            style={languageState.lang === "en" ||languageState.lang === "id" ? { direction: "ltr" } : null}
            id="scroll"
            className="scrollmenu"
          >
            {menuState.categories.map((data, index) => {
              return (
                <div id={data.enName} key={index}>
                  <button
                    onClick={() => handleSet(data.enName, data.id)}
                    className={type.value === data.enName ? "activeBtn" : null}
                  >
                    {data.name?? "not found"}
                  </button>
                </div>
              );
            })}
          </div>

          {categoryLoad ? (
            <div className="loader" style={{ height: "66vh" }}>
              <BeatLoader color={"#E47100"} loading={categoryLoad} size={30} />
            </div>
          ) : (
            <div className="container-fluid2">
              <div
                style={
                  i18n.language === "ar"
                    ? { direction: "rtl" }
                    : { direction: "ltr" }
                }
                className="row "
              >

                <div style={{'textAlign': 'center'}} >
                {menuState.products.products&&menuState.products.products.length ==0 ? (
                  <p style={{'fontSize': '28px', 'fontFamily': 'cairo', 'marginTop': '20vh'}} >{t('Single_product.noMeals')}</p>) : null
                }
                </div>
                {menuState.products.products &&
                  menuState.products.products.map((data, index) => {

                    
                    
                    return (
                      <FoodItem
                        key={index}
                        currency={
                          t('Currency')
                        }
                        name={
                          data.name ?? "not found"
                        }
                        active={data.active === 1}
                        image={data.image}
                        des={
                          languageState.lang === "ar"
                            ? data.arDescription
                            : data.enDescription
                        }
                        price={data.price}
                        id={data.id}
                      />
                    );
                  })}
              </div>
            </div>
          )}
          <BottomNav />
        </div>
      )}
    </div>
  );
}

export default MainPage;
