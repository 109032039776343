import * as actionType from '../../action/menu/actionType';

const initialState = {
    banners: [],
    error: null,
    loading: false,
    categories: [],
    products: [],
    menuType: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_BANNERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionType.FETCH_BANNERS_SUCCESS:
            return {
                ...state,
                banners: action.payload,
                loading: false,
                error: null,
            };
        case actionType.FETCH_BANNERS_FAILURE:
            return {
                ...state,
                banners: [],
                loading: false,
                error: action.error,
            };
        case actionType.FETCH_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionType.FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                loading: false,
                error: null,
            };
        case actionType.FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                categories: [],
                loading: false,
                error: action.error,
            };
        case actionType.FETCH_PRODUCTS_REQUEST: 
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionType.FETCH_PRODUCTS_SUCCESS: 
            return {
                ...state,
                products: action.payload,
                loading: false,
                error: null,
            };
        case actionType.FETCH_PRODUCTS_FAILURE:
            return {
                ...state,
                products: [],
                loading: false,
                error: action.error,
            };
        case actionType.SET_MENU_TYPE:
                return {
                    ...state,
                    menuType: action.payload,
                };
    
        default:
            return state;
    }
};

export default reducer;
