import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMenuType } from "../store/action/menu/menu";
import { Link } from "react-router-dom";
import { BackgroundImage } from "react-image-and-background-image-fade";

function ItemType(props) {
  const dispatch = useDispatch();
  const languageState = useSelector((state) => state.language);

  return (
    <div className="col-lg-4 col-sm-6 py-4">
      <Link
        onClick={() => {
          dispatch(setMenuType({ value: props.value, id: props.id }));
          localStorage.setItem(
            "activCateg",
            JSON.stringify({ value: props.value, id: props.id })
          );
        }}
        to="/menu/meals"
      >
        <div className="mainCategories">
          <BackgroundImage
            src={props.image}
            width="100%"
            className="categoryImg"
          />
          <div
            className="categoryTo"
            style={
              languageState.lang === "ar" ||languageState.lang === "ur"
                ? null
                : { flexDirection: "row-reverse" }
            }
          >
            <button
              style={languageState.lang === "ar" ||languageState.lang === "ur"? { opacity: "0" } : null}
              className={
                languageState.lang === "en" || languageState.lang == "id" ? "enarrow-button" : "arrow-button"
              }
            >
              <span className="arrow"></span>
            </button>
            <p>{props.name}</p>
            <button
              style={languageState.lang === "en" || languageState.lang == "id" ? { opacity: "0" } : null}
              className={
                languageState.lang === "en" || languageState.lang == "id" ? "enarrow-button" : "arrow-button"
              }
            >
              <span className="arrow"></span>
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ItemType;
