import "./App.css";
import MainPage from "./Pages/MainPage";
import MainMenu from "./Pages/MainMenu";
import CartItem from "./Pages/CartItem";
import Basket from "./Pages/Basket";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import LandingPage from "./Pages/LandingPage";
import OrderRecieved from "./Pages/OrderRecieved";
import Gift from "./Pages/Gift";
import PaymentSuccess from "./Pages/PaymentSuccess";
import Invoice from "./Pages/Invoice";
import FailedTransaction from "./Pages/FailedTransaction";
import PaymentCheck from "./Pages/PaymentCheck";
import NotFound from "./Pages/NotFound";
function App() {
  
 

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="*" element={<NotFound />} />

          <Route exact path="/menu" element={<MainPage />} />
          <Route exact path="/menu/meals" element={<MainMenu />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/meals/:id" element={<CartItem />} />
          <Route path="/invoice/:id" element={<Invoice />} />
          <Route path="/failed-payment" element={<FailedTransaction />} />
          <Route exact path="/payment-check" element={<PaymentCheck />} />

          <Route exact path="/order-recieved" element={<OrderRecieved />} />
          <Route exact path="/gift" element={<Gift />} />
          <Route exact path="/cart" element={<Basket />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
