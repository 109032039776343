import "../Styles/MainPage.css";
import { RiShoppingCartFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useParams, generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../store/action/language/language";
import { useDispatch, useSelector } from "react-redux";
import { BackgroundImage } from "react-image-and-background-image-fade";

function FoodItem(props) {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const id = props.id;
  //on clicking on an item user is redirected to /meals with meal id as params
  const handleProceed = (e) => {
    history(generatePath("/meals/:id", { id }));
  };

  //used to get language and direction
  useEffect(() => {
    const data = window.localStorage.getItem("LANGUAGE");

    if (data !== null) {
      i18n.changeLanguage(JSON.parse(data).language);
      document.getElementById("root").style.direction = JSON.parse(data).x;
      const { language, x } = JSON.parse(data);
      dispatch(setLanguage(language, x));
    }
  }, [dispatch]);

  return (
    <div
      style={
        i18n.language == "ar" || i18n.language == "ur"
          ? props.active
            ? { direction: "ltr", cursor: "pointer" }
            : { direction: "ltr", cursor: "not-allowed" }
          : props.active
          ? { direction: "rtl", cursor: "pointer" }
          : { direction: "rtl", cursor: "not-allowed" }
      }
      onClick={props.active ? handleProceed : null}
      className="col-md-6"
    >
      <div className=" removePadd">
        <div className="mmFoodItem row">
          <div className="col-7">
            <div className="wrapText">
              <p
                style={
                  languageState.lang == "en"|| languageState.lang == "id" ?  { textAlign: "left" } : null
                }
                className="mmName"
              >
                {props.name}
              </p>
            </div>
            <div
              className={
                languageState.lang == "en" || languageState.lang == "id"  ? "enwrapText2" : "wrapText2"
              }
            >
              <p
                style={
                  languageState.lang == "en" || languageState.lang == "id" ? { textAlign: "left" } : null
                }
                className="mmDetails"
              >
                {props.des}
              </p>
            </div>
            <div className="mmBottom">
              <button
                style={props.active ? null : { cursor: "not-allowed" }}
                className={languageState.lang == "en" || languageState.lang == "id" ? "enmmButton" : null}
              >
                <span>
                  <RiShoppingCartFill size={20} />
                </span>
              </button>
              <p
                style={
                  languageState.lang == "en" || languageState.lang == "id"
                    ? { direction: "ltr", textAlign: "left" }
                    : { direction: "rtl" }
                }
                className="mmPrice"
              >
                {props.price} {props.currency}
              </p>
            </div>
          </div>
          <div className="col-5 center">
            {/*   <div  className='foodItemImg' style={{ backgroundImage: `url(${props.image})` }}>
</div>*/}

            <BackgroundImage
              src={props.image}
              width="100%"
              className="foodItemImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default FoodItem;
