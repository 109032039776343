import "../Styles/MainPage.css";
import { RiShoppingCartFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import order from "../Images/order-recieved.svg";
import party from "../Images/Party.svg";
import { generatePath } from "react-router-dom";
import { setLanguage } from "../store/action/language/language";
import { useDispatch, useSelector } from "react-redux";

function OrderRecieved(props) {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();

  //checks language and translates screen and page title accordingly
  useEffect(() => {
    const data = window.localStorage.getItem("LANGUAGE");

    if (data !== null) {
      i18n.changeLanguage(JSON.parse(data).language);
      document.getElementById("root").style.direction = JSON.parse(data).x;
      const { language, x } = JSON.parse(data);
      dispatch(setLanguage(language, x));
    }
    if (i18n.language === "ar") {
      document.title = " مطاعم الرومانسية ";
    } else {
      document.title = " Al Romansiah Restaurant";
    }
  }, [dispatch]);

  const handleInvoice = () => {
    const id = `${localStorage.getItem("orderId")}-${localStorage
      .getItem("orderQueueId")
      .substring(1)}`;
    history(generatePath("/invoice/:id", { id }));
  };
  return (
    <div className="container" style={{ height: "100vh" }}>
      {/*<Link to="/CartItem/197" onClick={()=>setItemId(props.id)}>*/}

      <div className="orderRec">
        <img src={order} />
        {i18n.language == "en" || languageState.lang == "id" ? (
          <div style={{ direction: "ltr" }}>
            <p style={{ marginBottom: "0" }}>{t("Basket.order-rec")}</p>{" "}
            <span></span> <img src={party} />
          </div>
        ) : (
          <div>
            <img src={party}></img> <span></span>{" "}
            <p style={{ marginBottom: "0" }}>{t("Basket.order-rec")}</p>
          </div>
        )}

        <p
          style={
            i18n.language == "ar" ||i18n.language == "ur"
              ? { direction: "rtl", fontWeight: "600", marginBottom: "0" }
              : { direction: "ltr", fontWeight: "600", marginBottom: "0" }
          }
        >
          {t("Basket.orderQueueId")} :&nbsp;{" "}
          {localStorage.getItem("orderQueueId")}
        </p>

        <p style={{ fontWeight: "600" }}>
          {t("Basket.orderId")} :&nbsp; {localStorage.getItem("orderId")}
        </p>

        <div className={i18n.language == "ar" ||i18n.language == "ur" ? "orderBtns" : "orderBtnsEn"}>
          <Link to="/menu">
            <button>{t("Basket.orderBtn")}</button>
          </Link>
          <button className="invoiceBtn" onClick={handleInvoice}>
            {t("Basket.invoice")}
          </button>
        </div>
      </div>
    </div>
  );
}
export default OrderRecieved;
