import "../Styles/MainPage.css";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gift from "../Images/gift.svg";
import party from "../Images/Party.svg";
import { create_order } from "../store/action/order/order";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../store/action/language/language";

function Gift(props) {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const free = JSON.parse(window.localStorage.getItem("FREEMEALS"));
  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [msgId, setMsgId] = useState();
  const [msgUr, setMsgUr] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();

  //checks language and translates screen and page title accordingly
  useEffect(() => {
    const data = window.localStorage.getItem("LANGUAGE");

    if (data !== null) {
      i18n.changeLanguage(JSON.parse(data).language);
      document.getElementById("root").style.direction = JSON.parse(data).x;
      const { language, x } = JSON.parse(data);
      dispatch(setLanguage(language, x));
    }
    if (i18n.language === "ar") {
      document.title = " مطاعم الرومانسية ";
    } else {
      document.title = " Al Romansiah Restaurant";
    }
  }, [dispatch]);

  //on pressing continue create_order api is called taking data2 as body *stored in Bakset component*
  // if payment method is tap user is redirected to third party service else, user is redirected to /order-recieved

  function handleOrder() {
    const token = window.localStorage.getItem("TOKEN");
    const cartId = window.localStorage.getItem("CartId");
    const data2 = window.localStorage.getItem("ORDER");
    create_order(JSON.parse(data2), JSON.parse(token), dispatch)
      .then((response) => {
        if (JSON.parse(data2).payment === "tap") {
          localStorage.setItem("orderId", response.data.data.orderId);
          if (response.data.data.url === null) {
            //localStorage.setItem("menuRoute", "false");
          } else {
            window.location.href = response.data.data.url;
          }
        } else if (JSON.parse(data2).payment === "checkout") {
          localStorage.removeItem("cardToken");
          localStorage.setItem("orderId", response.data.data.orderId);
          localStorage.setItem("orderQueueId", response.data.data.orderQueueId);

          if (
            response.data.data.url === null ||
            response.data.data.url === "" ||
            response.data.data.url == undefined
          ) {
            history("/order-recieved");
          } else {
            window.location.href = response.data.data.url;
          }
        } else {
          localStorage.setItem("orderId", response.data.data.orderId);

          history("/order-recieved");
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setErrorMsgModel(true);
          setMsgEn("Please wait, there is a request under process");
          setMsgAr("الرجاء الانتظار ، هناك طلب قيد التنفيذ");
          setMsgId("Mohon tunggu, ada permintaan yang sedang diproses"); // Indonesian translation
          setMsgUr("براہ کرم انتظار کریں، ایک درخواست کام میں ہے"); // Urdu translation
                  }
      });
  }

  return (
    <div className="container" style={{ height: "100vh" }}>
      {/*<Link to="/CartItem/197" onClick={()=>setItemId(props.id)}>*/}

      <div className="orderRec">
        <img src={gift} />

        {i18n.language == "en" ? (
          <div style={{ direction: "ltr" }}>
            <p>{t("Basket.gift1")}</p>
            <span></span> <img src={party}></img>
            <p className="gift-p">{t("Basket.gift2")}</p>
            <div className="freeList">
              <ul>
                {free &&
                  free.map((data, index) => {
                    return (
                      <li key={index}>
                        {i18n.language == "ar" ? data.arNAme : data.enName}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <img src={party}></img> <span></span> <p>{t("Basket.gift1")}</p>
            <p className="gift-p">{t("Basket.gift2")}</p>
            <div className="freeList" style={{ direction: "rtl" }}>
              <ul>
                {free &&
                  free.map((data, index) => {
                    return (
                      <li style={{ textAlign: "right" }} key={index}>
                        {i18n.language == "ar" ? data.arName : data.enName}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
        {errorMsgModel ? (
          <span style={{ display: "block" }} className="err">
            {languageState == "en" ? msgEn : languageState == "ar"?  msgAr : languageState =="ur"? msgUr:msgId}
          </span>
        ) : null}
        <button onClick={handleOrder}>{t("Basket.button")}</button>
      </div>
    </div>
  );
}
export default Gift;
