import AxiosProvider from "../../../AxiosProvider";
import AxiosProvider2 from "../../../AxiosProvider2";
import {applicationId} from '../../../config'
import * as actionType from '../cart/actionType'



export const orderCreationSuccess = () => ({
    type: actionType.ORDER_CREATION_SUCCESS,
   
  });


export const create_order = (data,token, dispatch) => {
    const cart = JSON.parse(localStorage.getItem("cart"))
    const branchId = cart[0].branchId
    const paymentMethod = "checkout"
    const products = cart
    const platform = "android-pos";



    var body={

        token:data.token,
       
        paymentMethod:paymentMethod,
        code:data.code,
        notes:"now",
        date:" ",
        now: "true",
        time:"",
        branchId: branchId,
        typeId: 1,
        products:products,
        platform:platform
          }
    
    return AxiosProvider.post(`/orders`, body,
    {   headers: {
        Authorization:  `Bearer ${token}`
       , Accept: 'application/json'
    },
    params:{
        applicationId:applicationId
    }}).then((res)=> {
        dispatch(orderCreationSuccess())
        return res
    })
}


export const payment = (id,token) => {
    return AxiosProvider.get('/orders/tap/payment-status/'+id,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
} 
export const pay_again = (orderId,token) => {
    return AxiosProvider.get( 'orders/' + orderId + '/payment-url', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
} 
 
export const invoiceData = (id,code) => {
    return AxiosProvider.get(`/orders/invoice/${id}/${code}`)
}



export const checkPayment = (token,sessionId,currency) => {

    
    
    return AxiosProvider.get( `/orders/checkout/get-paymentlink-details?paymentLinkId=${sessionId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'countryId': currency=="SAR"?1:2

        },params:{
            type:"order",
            platform: "qr",
            currency:currency
        }})
} 