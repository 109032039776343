
import * as actionType from '../menu/actionType'
import AxiosProvider from "../../../AxiosProvider";
import AxiosProvider2 from "../../../AxiosProvider2";
import {applicationId,} from '../../../config'

export const getBanner = () => async(dispatch) => {

   

    try {

        dispatch({
            type:actionType.FETCH_BANNERS_REQUEST
        })

        const countryId = parseInt(localStorage.getItem("countryId"))
        const language = localStorage.getItem("i18nextLng")
        const response = await AxiosProvider.get(`/banners?type=website&countryId=${countryId}&language=${language}`);
        const banners = response.data.data

        dispatch({
            type:actionType.FETCH_BANNERS_SUCCESS,
            payload: banners
        })

    }catch(e) {
        dispatch({
            type: actionType.FETCH_BANNERS_FAILURE,
            error: e
        })
        console.log(e)
    }
}


export const getCategories = () => async(dispatch, getState) => {
    try {

        dispatch({
            type:actionType.FETCH_CATEGORIES_REQUEST
        })

        const branchId = parseInt(localStorage.getItem("branchId"))
        

        const lanauge = getState().language.lang

        const response =await AxiosProvider.get(`/categories?applicationId=${applicationId}&type=featured&branchId=${branchId}`, {
            headers:{
                language:lanauge
            }
        })
        const categories = response.data.data

        dispatch({
            type:actionType.FETCH_CATEGORIES_SUCCESS,
            payload: categories
        })

    }catch(e) {
        dispatch({
            type: actionType.FETCH_CATEGORIES_FAILURE,
            error: e
        })
        console.log(e)
    }
}

export const getProducts = (id, data, brnchId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: actionType.FETCH_PRODUCTS_REQUEST
        });


        const queryParams = {
            applicationId: applicationId,
            categoryId: id,
            branchId: brnchId,
            categoryMessage: true,
        };

        const response = await AxiosProvider.get('/products', {
            params: queryParams,
            data,
            headers:{
                language: getState().language.lang
            }
        });

        const products = response.data.data;

        dispatch({
            type: actionType.FETCH_PRODUCTS_SUCCESS,
            payload: products
        });
    } catch (e) {
        console.log(e)
        dispatch({
            type: actionType.FETCH_PRODUCTS_FAILURE,
            error: e
        });
        console.log(e);
    }
};


export const setMenuType = (menuType) => ({
    type: actionType.SET_MENU_TYPE,
    payload: menuType,
});
