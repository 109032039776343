import * as actionType from "../cart/actionType";
import AxiosProvider from "../../../AxiosProvider";
import AxiosProvider2 from "../../../AxiosProvider2";
import { applicationId } from "../../../config";

export const getProduct = (id, data, brnchId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionType.FETCH_PRODUCT_REQUEST,
    });
    const lang = getState().language.lang


    const response = await AxiosProvider2.get(`/products/${id}`, {

      params: {
        applicationId: applicationId,
        branchId: brnchId,
      },
      
      data,
      headers:{
        language:lang
      }
    });
    dispatch({
      type: actionType.FETCH_PRODUCT_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: actionType.FETCH_PRODUCT_FAILURE,
      error: e,
    });
  }
};

export const fetchPaymentMethod = (cartId, token) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.FETCH_PAYMENT_METHODS_REQUEST,
    });

    const response = await AxiosProvider.get(
      `/cart/${cartId}/payment-methods`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        params: {
          applicationId: applicationId,
        },
      }
    );

    dispatch({
      type: actionType.FETCH_PAYMENT_METHODS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: actionType.FETCH_PAYMENT_METHODS_FAILURE,
      error: error,
    });
  }
};

export const fetchBasket = (token) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.FETCH_BASKET_REQUEST,
    });

    

    const cart = JSON.parse(localStorage.getItem("cart"));

    console.log(cart);

    dispatch({
      type: actionType.FETCH_BASKET_SUCCESS,
      // payload: response.data.data
      payload: cart,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: actionType.FETCH_BASKET_FAILURE,
      error: e,
    });
  }
};

export const fetchRecommendedProducts =
  (token, branchId) => async (dispatch) => {
    try {
      dispatch({
        type: actionType.FETCH_RECOMMENDED_PRODUCTS_REQUEST,
      });

      const response = await AxiosProvider.get("recommendedProducts/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: {
          branchId: branchId,
          applicationId: applicationId,
        },
      });

      dispatch({
        type: actionType.FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
        payload: response.data.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: actionType.FETCH_RECOMMENDED_PRODUCTS_FAILURE,
        error: e,
      });
    }
  };

  export const addToCart = (data, token) => async (dispatch) => {
    try {
      dispatch({
        type: actionType.ADD_TO_CART_REQUEST,
      });
      console.log(data);
      console.log(data.additionalId);
  
      let existingCart = localStorage.getItem("cart");
      existingCart = existingCart ? JSON.parse(existingCart) : [];
      var additionals;
      if (data.additionalId === null) {
        additionals = [];
      } else {
        const idNumber = Number(data.additionalId);
        if (!isNaN(idNumber)) {
          additionals = [idNumber];
        } else {
          additionals = [];
        }
      }
      
      let productExists = false;
      for (let item of existingCart) {
        if (item.productId === data.productId && item.sizeId === data.sizeId && JSON.stringify(item.additionals) === JSON.stringify(additionals) && JSON.stringify(item.extras) === JSON.stringify(data.extras)) {
          item.quantity += data.quantity; 
          productExists = true;
          break;
        }
      }
  
      if (!productExists) {
        existingCart.push({
          id: data.productId,
          quantity: data.quantity,
          sizeId: data.sizeId,
          additionals: additionals,
          extras: data.extras,
          branchId: data.branchId,
          typeId: data.typeId,
          platform: data.platform,
          appVersion: data.appVersion,
          name:data.name,
          description:data.description,
          productId: data.productId,
          enName: data.enName,
          arName: data.arName,
          enDescription: data.enDescription,
          arDescription: data.arDescription,
          image: data.image,
          price: data.price,
          currencyAr: data.currencyAr,
          currencyEn: data.currencyEn,
          mealId: data.mealId,
        });
      }
  
      localStorage.setItem("cart", JSON.stringify(existingCart));
  
      dispatch({
        type: actionType.ADD_TO_CART_SUCCESS,
        payload: existingCart,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: actionType.ADD_TO_CART_FAILURE,
        error: error,
      });
    }
  };
  
