import React, { useEffect, useState } from "react";

import success from "../Images/success.svg";
import failed from "../Images/faild.svg";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { payAgain_checkout } from "../../src/store/action/cart/editCart";

function FailedTransaction(props) {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("TOKEN");
  const [state, setState] = useState(false);
  const [cardValid, setCardValid] = useState(false);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  let history = useNavigate();

  //if operation was successful and user clicks on track order
  //user is redirected to /order-track (OrderTracking component in /Pages) with order id as params

  const handleInvoice = () => {
    const id = `${localStorage.getItem("orderId")}-${localStorage
      .getItem("orderQueueId")
      .substring(1)}`;
    history(generatePath("/invoice/:id", { id }));
  };

  function payAgain(paymentToken) {
    payAgain_checkout(
      JSON.parse(token),
      paymentToken,
      localStorage.getItem("orderId")
    )
      .then((response) => {
        if (response.data.url !== "" && response.data.url !== null) {
          window.location.href = response.data.url;
        } else if (
          response.data.message == "warning" &&
          response.data.data == "This order has been paid."
        ) {
          setErrorMsgModel(true);
          setMsgAr("لقد تم دفع هذا الطلب بالفعل");
          setMsgEn("This order has been paid.");
          setPaymentLoad(false);
          Frames.init();
        } else if (response.data.message == "warning") {
          setErrorMsgModel(true);
          setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");
          setPaymentLoad(false);
          Frames.init();
        } else {
          setState(true);
        }
      })
      .catch(function (err) {
        setErrorMsgModel(true);
        setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
        setMsgEn("Transaction has been failed,please try again");
        setPaymentLoad(false);

        Frames.init();
      });
  }
  return (
    <div>
      <>
        <div className="container" style={{ height: "100vh" }}>
          <div className="orderRec" style={{ padding: "1em" }}>
            {state ? (
              <div className="shareForm ">
                <div>
                  <img style={{ marginBottom: "1em" }} src={success} />
                </div>
                <p style={{ color: "rgb(99, 50, 53)", display: "block" }}>
                  {t("payment.success_payment")}
                </p>
                <p
                  style={
                    i18n.language == "ar" ||i18n.language == "ur"
                      ? {
                          direction: "rtl",
                          fontWeight: "600",
                          marginBottom: "0",
                          display: "block",
                        }
                      : {
                          direction: "ltr",
                          fontWeight: "600",
                          marginBottom: "0",
                          display: "block",
                        }
                  }
                >
                  {t("Basket.orderQueueId")} :&nbsp;{" "}
                  {localStorage.getItem("orderQueueId")}
                </p>

                <p style={{ fontWeight: "600", display: "block" }}>
                  {t("Basket.orderId")} :&nbsp;{" "}
                  {localStorage.getItem("orderId")}
                </p>

                <div
                  className={
                    i18n.language == "ar" ||i18n.language == "ur" ?  "orderBtns" : "orderBtnsEn"
                  }
                >
                  <Link to="/menu/meals">
                    <button>{t("Basket.orderBtn")}</button>
                  </Link>
                  <button className="invoiceBtn" onClick={handleInvoice}>
                    {t("Basket.invoice")}
                  </button>
                </div>
              </div>
            ) : (
              <div className="shareForm ">
                <img style={{ marginBottom: "1em" }} src={failed} />

                <p style={{ color: "rgb(99, 50, 53)", display: "block" }}>
                  {t("payment.notSuccess_payment")}
                </p>

                <div
                  className="creditCard"
                  style={{
                    margin: "auto",
                    marginTop: "2em",
                    maxWidth: "600px",
                    direction: "rtl",
                  }}
                >
                  <Frames
                    cardValidationChanged={(e) => {
                      setCardValid(e.isValid);
                    }}
                    config={{
                      publicKey: `${process.env.REACT_APP_PAYMENT}`,
                      schemeChoice: true,
                    }}
                    cardTokenized={(e) => {
                      payAgain(e.token);
                    }}
                  >
                    <CardNumber />
                    <div className="date-and-code">
                      <ExpiryDate />
                      <Cvv />
                    </div>

                    {/* Or if you want to use single frames: */}
                    {/* <CardFrame /> */}

                    {paymentLoad ? (
                      <div
                        style={{
                          height: "auto",
                          margin: "1em 0",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                        className="loader"
                      >
                        <BeatLoader
                          color={"#E47100"}
                          loading={paymentLoad}
                          size={30}
                        />
                        <p className="paymentLoad">{t("payment.processing")}</p>
                      </div>
                    ) : (
                      <button
                        disabled={!cardValid}
                        id="pay-button"
                        onClick={() => {
                          setPaymentLoad(true);
                          Frames.submitCard();
                        }}
                      >
                        {t("payment.pay_again")}
                      </button>
                    )}
                  </Frames>
                  <button
                    style={{
                      width: "100%",
                      margin: "0",
                      marginBottom: "0.5em",
                    }}
                    onClick={handleInvoice}
                  >
                    {t("Basket.invoice")}
                  </button>
                  {i18n.language == "ar" ||i18n.language == "ur" ? (
                    <span className="errMsg" style={{ fontSize: "16px" }}>
                      {errorMsgModel ? msgAr : null}
                    </span>
                  ) : (
                    <span className="errMsg" style={{ fontSize: "16px" }}>
                      {errorMsgModel ? msgEn : null}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}

export default FailedTransaction;
