import React, { useEffect, useState } from "react";
import axios from "axios";
import success from "../Images/success.svg";
import failed from "../Images/faild.svg";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { checkPayment } from "../store/action/order/order";

import { DotLoader } from "react-spinners";
import { payAgain_checkout } from "../../src/store/action/cart/editCart";
import { useSelector } from "react-redux";

function PaymentCheck(props) {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("TOKEN");
  const [state, setState] = useState(false);
  const [cardValid, setCardValid] = useState(false);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [msgId, setMsgId] = useState();
  const [msgUr, setMsgUr] = useState();
  const [warning, setWaringing] = useState(false);
  const [arErr, setArrErr] = useState("");
  const [enErr, setEnrErr] = useState("");

  const [loading, setLoading] = useState(true);
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  let history = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let sessionId = params.get("cko-session-id");
  const branch = JSON.parse(localStorage.getItem("currentBranch"));

  const language = useSelector((state)=> state.language.lang)

  useEffect(() => {
    i18n.changeLanguage(language);

    window.scrollTo(0, 0);
    checkPayment(JSON.parse(token), sessionId, branch.enCurrency)
      .then((response) => {
        if (response.data.data.approved == true) {
          if (response.data.data.status == "Authorized") {
            setAuthorized(true);
            // setTimeout(function () {
            //   // handleInvoice();
            // }, 3500);
          }
          setState(true);
        } else {
          setState(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setState(false);
        setLoading(false);

        if (error.response.data.message == "warning") {
          setWaringing(true);
          setArrErr("لقد تمت عملية الدفع بالفعل");
          setEnrErr(error.response.data.error);
        }
      });
  }, []);

  const handleInvoice = () => {
    const id = `${localStorage.getItem("orderId")}-${localStorage
      .getItem("orderQueueId")
      .substring(1)}`;
    history(generatePath("/invoice/:id", { id }));
  };

  function payAgain(paymentToken) {
    payAgain_checkout(
      JSON.parse(token),
      paymentToken,
      localStorage.getItem("orderId")
    )
      .then((response) => {
        if (response.data.url !== "" && response.data.url !== null) {
          window.location.href = response.data.url;
        } else if (
          response.data.message == "warning" &&
          response.data.data == "This order has been paid."
        ) {
          setErrorMsgModel(true);
          setMsgAr("لقد تم دفع هذا الطلب بالفعل");
          setMsgEn("This order has been paid.");
          setMsgId("Pesanan ini telah dibayar.");
          setMsgUr("اس آرڈر کا ادا کر دیا گیا ہے۔");

          setPaymentLoad(false);
          Frames.init();
        } else if (response.data.message == "warning") {
          setErrorMsgModel(true);
          setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");
          setMsgId("Transaksi gagal, silakan coba lagi");
          setMsgUr("لین دین میں ناکامی کا واقعہ، براہ کرم دوبارہ کوشش کریں");

          setPaymentLoad(false);
          Frames.init();
        } else {
          setState(true);
        }
      })
      .catch(function (err) {
        setErrorMsgModel(true);
        setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
        setMsgEn("Transaction has been failed,please try again");
        setPaymentLoad(false);

        Frames.init();
      });
  }
  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <div className="container" style={{ height: "100vh" }}>
            <div className="orderRec" style={{ padding: "1em" }}>
              {state ? (
                <div className="shareForm ">
                  <div>
                    {authorized ? (
                      <div
                        style={{ height: "10vw", marginBottom: "1.5em" }}
                        className="loader"
                      >
                        <DotLoader color={"#E47100"} size={80} />
                      </div>
                    ) : (
                      <img style={{ marginBottom: "1em" }} src={success} />
                    )}
                  </div>
                  <p style={{ color: "rgb(99, 50, 53)", display: "block" }}>
                    {authorized
                      ? t("payment.waiting")
                      : t("payment.success_payment")}
                  </p>
                  <p
                    style={
                      language == "ar" || language == "ur"
                        ? {
                            direction: "rtl",
                            fontWeight: "600",
                            marginBottom: "0",
                            display: "block",
                          }
                        : {
                            direction: "ltr",
                            fontWeight: "600",
                            marginBottom: "0",
                            display: "block",
                          }
                    }
                  >
                    {t("Basket.orderQueueId")} :&nbsp;{" "}
                    {localStorage.getItem("orderQueueId")}
                  </p>

                  <p style={{ fontWeight: "600", display: "block" }}>
                    {t("Basket.orderId")} :&nbsp;{" "}
                    {localStorage.getItem("orderId")}
                  </p>

                  {authorized ? null : (
                    <div
                      style={{ marginTop: "1em" }}
                      className={
                        language == "ar" || language == "ur"
                          ? "orderBtns"
                          : "orderBtnsEn"
                      }
                    >
                      <Link to="/menu/meals">
                        <button>{t("Basket.orderBtn")}</button>
                      </Link>
                      <button className="invoiceBtn" onClick={handleInvoice}>
                        {t("Basket.invoice")}
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {warning ? (
                    <div className="shareForm ">
                      <img style={{ marginBottom: "1em" }} src={failed} />

                      <p style={{ color: "rgb(99, 50, 53)" }}>
                        {language == "ar" ? arErr : enErr}
                      </p>
                    </div>
                  ) : (
                    <div className="shareForm ">
                      <img style={{ marginBottom: "1em" }} src={failed} />

                      <p style={{ color: "rgb(99, 50, 53)", display: "block" }}>
                        {t("payment.notSuccess_payment")}
                      </p>

                      <div
                        className="creditCard"
                        style={{
                          margin: "auto",
                          marginTop: "2em",
                          maxWidth: "600px",
                          direction: "rtl",
                        }}
                      >
                        <Frames
                          cardValidationChanged={(e) => {
                            setCardValid(e.isValid);
                          }}
                          config={{
                            publicKey: `${process.env.REACT_APP_PAYMENT}`,
                            schemeChoice: true,
                          }}
                          cardTokenized={(e) => {
                            payAgain(e.token);
                          }}
                        >
                          <CardNumber />
                          <div className="date-and-code">
                            <ExpiryDate />
                            <Cvv />
                          </div>

                          {/* Or if you want to use single frames: */}
                          {/* <CardFrame /> */}

                          {paymentLoad ? (
                            <div
                              style={{
                                height: "auto",
                                margin: "1em 0",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                              className="loader"
                            >
                              <BeatLoader
                                color={"#E47100"}
                                loading={paymentLoad}
                                size={30}
                              />
                              <p className="paymentLoad">
                                {t("payment.processing")}
                              </p>
                            </div>
                          ) : (
                            <button
                              disabled={!cardValid}
                              id="pay-button"
                              onClick={() => {
                                setPaymentLoad(true);
                                Frames.submitCard();
                              }}
                            >
                              {t("payment.pay_again")}
                            </button>
                          )}
                        </Frames>

                        {/* <button
                          style={{
                            width: "100%",
                            margin: "0",
                            marginBottom: "0.5em",
                          }}
                          onClick={handleInvoice}
                        >
                          {t("Basket.invoice")}
                        </button> */}
                        {language === "ar" ? (
                          <span className="errMsg" style={{ fontSize: "16px" }}>
                            {errorMsgModel ? msgAr : null}
                          </span>
                        ) : language === "id" ? (
                          <span className="errMsg" style={{ fontSize: "16px" }}>
                            {errorMsgModel ? msgId : null}
                          </span>
                        ) : language === "ur" ? (
                          <span className="errMsg" style={{ fontSize: "16px" }}>
                            {errorMsgModel ? msgUr : null}
                          </span>
                        ) : (
                          <span className="errMsg" style={{ fontSize: "16px" }}>
                            {errorMsgModel ? msgEn : null}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}{" "}
    </div>
  );
}

export default PaymentCheck;
