import "../Styles/MainPage.css";
import logo from "../Images/MainLogo.svg";
import ItemType from "../Components/ItemType";
import BottomNav from "../Components/BottomNav";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { setLanguage } from "../store/action/language/language";
import { getBanner, getCategories } from "../store/action/menu/menu";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import { AiOutlineGlobal } from "react-icons/ai";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from "react-redux";

function MainPage() {
  const { t, i18n } = useTranslation();
  const [branchName, setBranchName] = useState();
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menu);
    const [menuVisible, setMenuVisible] = useState(false);


  const branch = JSON.parse(localStorage.getItem("currentBranch"))

  //for changing language and direction
  const changeLanguage = (language, direction) => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language, direction));
    document.getElementById("root").style.direction = direction;
  };

  

  useEffect(() => {
    const storedBranchName = JSON.parse(
      window.localStorage.getItem("branchName")
    );
    setBranchName(storedBranchName);

    if (storedBranchName) {
    }
  }, [setBranchName, dispatch]);

  // Combine common logic into a single function
  const persistDataAndFetch = () => {
   
    dispatch(getBanner());
    dispatch(getCategories());
  };

  useEffect(persistDataAndFetch, [dispatch,languageState.lang]);
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLanguageChange = (lang, dir) => {
    changeLanguage(lang, dir);
    setMenuVisible(false);
  };
  const menuStyle = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    position: "absolute",
    top: "40%",
    left: languageState.lang == "ar" || languageState.lang == "ur" ? 80 : 0,
    zIndex: 1000,
  };

  const buttonStyle = {
    border: "none",
    backgroundColor: "#fff",
    cursor: "pointer",
    padding: "10px 20px",
    textAlign: "left",
    width: "100%",
    marginBottom: "5px",
  };

  const lastButtonStyle = {
    ...buttonStyle,
    marginBottom: 0,
  };

  useEffect(() => {
    document.title =
      languageState.lang === "ar" ? "مطاعم الرومانسية" : "Al Romansiah Restaurant";
  }, [languageState.lang]);

  return (
    <div className="MainPage ">
      {menuState.loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={menuState.loading} size={30} />
        </div>
      ) : (
        <div>
          <div className="container">
            <div className="mpNav">
              <div style={{'display':'flex',  'position': 'relative'}} className="langSwitch">
                <img src={logo} alt="logo" />
                <div style={{ display: "inline-block" }}>
                <AiOutlineGlobal className="globe" size={25} />

                <button onClick={toggleMenu} className="langBtn">
                {languageState.lang.toUpperCase()}
              </button>
              {menuVisible && (
              <div style={menuStyle}>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("en", "rtl")}
                >
                  EN
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("ar", "ltr")}
                >
                  AR
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("id", "rtl")}
                >
                  ID
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("ur", "ltr")}
                >
                  UR
                </button>
              </div>
            )}
                  {/* <p>{branchName.arName ||branchName.enName &&  languageState.lang=="ar"?branchName.arName:branchName.enName }</p> */}
                </div>
              </div>
              <p
                  style={
                   {'textAlign':'center', 'color':'#e47100'}
                  }
                  className="mpP-2"
                >
                 {languageState.lang =="ar" ? branch.arName :branch.enName}
                </p>
              <div>
                
                <p
                  style={
                    languageState.lang == "en" || languageState.lang == "id"
                      ? { textAlign: "left" }
                      : { textAlign: "right" }
                  }
                  className="mpP-1"
                >
                  {t("home.title1")}{" "}
                </p>
                <p
                  style={
                    languageState.lang == "en" || languageState.lang == "id"
                      ? { textAlign: "left" }
                      : { textAlign: "right" }
                  }
                  className="mpP-2"
                >
                  {t("home.title2")}
                </p>

               
              </div>
              
            </div>
            <div style={{ marginBottom: "5%" }}>
              <Swiper
                dir={languageState.lang == "ar" ||i18n.language == "ur" ? "ltr" : "rtl"}
                style={{
                  "--swiper-pagination-color": "#E47100",
                  "border-radius": "20px",
                }}
                pagination={{ clickable: true }}
                key={languageState.lang}
                navigation={true}
                centeredSlides={true}
                loop={true}
                spaceBetween={30}
                autoplay={{ delay: 3500, disableOnInteraction: false }}
                modules={[Pagination, Navigation, Autoplay]}
                className={
                  languageState.lang == "ar" ||i18n.language == "ur"
                    ? "myCarousel btnsEn"
                    : "myCarousel btnsAr"
                }
              >
                {menuState.banners &&
                  menuState.banners.map((data, index) => (
                    <SwiperSlide key={index}>
                      <BackgroundImage
                        src={data.image}
                        width="100%"
                        className="crslBackground"
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div>
              <div
                style={
                  languageState.lang == "ar" ||i18n.language == "ur"
                    ? { direction: "rtl" }
                    : { direction: "ltr" }
                }
                className="row gy-4 mpMain"
              >
                {menuState.categories.map((data, index) => {
                  return (
                    <ItemType
                      key={index}
                      name={
                        data.name ?? "Not Found"
                      }
                      value={data.enName}
                      image={data.featuredImage}
                      id={data.id}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ height: "15vh" }}></div>
          <BottomNav />
        </div>
      )}
    </div>
  );
}

export default MainPage;

{
  /*
<div  className='crslBackground' style={{ backgroundImage: `url(${data.image})` }}>

</div>*/
}

{
  /*<ItemType value="chicken" pos={-885}/>
<ItemType  value="meat" pos={0}/>*/
}

{
  /*
<Carousel>

{banners.map((data, index)=>{
       return (
    
        <Carousel.Item interval={3000} key={index}>
        <img 
          className="d-block w-100"
          src={`${data.image}`}
          alt="First slide"
        />
        
      </Carousel.Item>
     );})}
 
 
       </Carousel>*/
}
