import "../Styles/MainPage.css";
import { RiShoppingCartFill } from "react-icons/ri";
import BasketScroll from "../Components/BasketScroll";
import { useState, useEffect } from "react";
import { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useParams } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { HiOutlineChevronDown } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../Images/SmalLogo.svg";
import BeatLoader from "react-spinners/BeatLoader";
import PhoneModal from "../Components/PhoneModal";
import MealNotAvail from "../Components/MealNotAvail";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { setLanguage } from "../store/action/language/language";
import { useDispatch, useSelector } from "react-redux";
import { getProduct, addToCart } from "../store/action/cart/cart";
import { fetchBasket } from "../store/action/cart/cart";

function CartItem() {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [quan, setQuan] = useState(1);
  const [sizes, setSize] = useState();
  const [sizeIndex, setSizeIndex] = useState(0);
  const [noSizes, setNoSizes] = useState(false);
  const [noSizesMsg, setNoSizesMsg] = useState(false);

  const [additionals, setAdditionals] = useState();
  const [chosenAddition, setChosenAddition] = useState(null);
  const [chosenSize, setChosenSize] = useState();
  const [chosenExtras, setChosenExtras] = useState([]);
  const [additionalsRequired, setAdditionalsRequired] = useState();
  const [extras, setExtras] = useState([]);
  // const [reommend,setreommend]=useState([]);
  const [currency, setCurrency] = useState();
  const [total, setTotal] = useState(0);
  const [totalCal, setTotalCal] = useState(0);
  const [additionalIndex, setAdditionalIndex] = useState(null);
  const [isShowingAlert, setShowingAlert] = useState(false);
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { loading, error, product } = useSelector((state) => state.cart);
  const [modal, setModal] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const { id } = useParams();
  const [busy, setBusy]= useState(false)
  const [isBusyPopupVisible, setIsBusyPopupVisible] = useState(false);
  // const location= useLocation();

  //checks if user has already got a token (if user hasn't got a token on clicking add to cart user is shown a pop-up with phone input field)
  //checks language and translates screen and page title accordingly
  useEffect(() => {
    let token = localStorage.getItem("TOKEN");

    if (token) {
      setHasToken(true);
    } else {
      setHasToken(false);
    }
    const language = languageState.lang;
    const direction = languageState.dir;

    i18n.changeLanguage(language);
    document.getElementById("root").style.direction = direction;
    dispatch(setLanguage(language, direction));

    if (language === "ar") {
      document.title = "مطاعم الرومانسية";
    } else {
      document.title = "Al Romansiah Restaurant";
    }
  }, [dispatch]);

  //Single_product api call takes meal id from url, null body and branchId(stored in landing page) ()=> gets meal data
  //then initial values for fields are set
  useEffect(() => {
    const branchId = JSON.parse(localStorage.getItem("branchId"));
    dispatch(getProduct(id, null, branchId));
  }, [dispatch, id]);

  useEffect(() => {
    if (product) {
      setSize(product.sizes);
      // setreommend(product.recommended);
      setAdditionals(product.sizes[0]?.additionals || []);
      console.log(additionals)
      setChosenSize(product.sizes[0]);
      setExtras(product.extras);
      setAdditionalsRequired(product.sizes[0]?.isAdditionalRequired || false);
      if (product.sizes[0]?.isAdditionalRequired) {
        setTotal(product.sizes[0].additionals[0].price);
        setTotalCal(product.sizes[0].additionals[0].calories);
        setAdditionalIndex(0);
        setChosenAddition(product.sizes[0].additionals[0]);
      } else {
        setAdditionalIndex(null);
        setTotal(product.sizes[0].price);
        setTotalCal(product.sizes[0].calories);
        setChosenAddition({});
      }
      if (product.sizes.length === 0) {
        setNoSizes(true);
        setNoSizesMsg("No sizes available for this product.");
      }
    }
    if (error) {
      // setNoSizes(true);
      // setNoSizesMsg(error.toString());
    }
  }, [product, error]);

  //on adding to cart
  //Add_to_cart api call takes (data object) and token
  //user is then shown an alert and redirected to /menu/meals
  function handleAdd() {
    
    const data = {
      branchId: JSON.parse(localStorage.getItem("branchId")),
      typeId: 1,
      addressId: "",
      platform: "qr",
      appVersion: "1.0.0",
      productId: product.id,
      quantity: quan,
      sizeId: chosenSize.id,
      additionalId: chosenAddition ? chosenAddition.id : null,
      extras: chosenExtras,
      enName: product.enName,
      arName: product.arName,
      name:product.name,
      description:product.description,
      enDescription: product.enDescription,
      arDescription: product.arDescription,
      image: product.image,
      price:total,
      currencyAr:product.currencyAr,
      currencyEn:product.currencyEn,
      mealId: product.mealId
    };
    console.log(product)

    const token = window.localStorage.getItem("TOKEN");
    const branchData = JSON.parse(localStorage.getItem("currentBranch"))

    if(branchData.busy) {
      setIsBusyPopupVisible(true); // Show the popup
      setTimeout(() => {
        setIsBusyPopupVisible(false); // Hide the popup after 5 seconds
      }, 5000);
      return; // Exit the function to prevent adding to cart
    }


    dispatch(addToCart(data, JSON.parse(token)))
      .then(() => {
        setShowingAlert(true);
        setTimeout(() => {
          history("/menu/meals");
          const token = window.localStorage.getItem("TOKEN");
        }, 4000);
        if (!error) {
          dispatch(fetchBasket(JSON.parse(token)));
        }
      })

      .catch((err) => {
        setNoSizesMsg(
          i18n.language == "ar"
            ? "هذه الوجبة غير متاحة"
            : "This meal is not available"
        );
        setNoSizes(true);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      });
  }

  //calculate total price & calories on additionals change
  //saves chosen addition
  function calc(calories, price, index) {
    setTotal(price);
    setAdditionalIndex(index);
    setTotalCal(calories);
    setChosenAddition(chosenSize.additionals[index]);
  }

  //setting price and calories on size change for products with no additionals required
  function Totals(calories, price, data) {
    setTotal(price);
    setTotalCal(calories);
    setChosenSize(data);
  }

  //on changing size for products with additionals required
  //displayed additionals and extras are changed to that of the chosen size
  //chosen size index is saved
  //total price and calories are set to inital value of chosen size
  //first additional of chosen size is checked
  function handleChange(index) {
    setSizeIndex(index);
    setAdditionals(product.sizes[index].additionals);
    setChosenSize(product.sizes[index]);
    var elements = document.getElementsByClassName("additions");

    // setAdditionalsRequired(product.sizes[index].isAdditionalRequired);
    setExtras(product.extras);

    if (product.sizes[index].isAdditionalRequired) {
      setTotal(product.sizes[index].additionals[0].price);
      setAdditionalIndex(0);
      setTotalCal(product.sizes[index].additionals[0].calories);
      for (var i = 0; i < elements.length; i++) {
        if (i == 0) {
          elements[i].checked = true;
        }
      }
    } else {
      setChosenAddition(null);
      setAdditionalIndex(null);
      setTotal(product.sizes[index].price);
      setTotalCal(product.sizes[index].calories);

      for (var i = 0; i < elements.length; i++) {
        elements[i].checked = false;
      }
    }
  }

  //on checking or unchecking an extra
  //loops on extras and adds index of checked extras to array
  function handleExtras() {
    var checkboxes = document.getElementsByClassName("extras");
    var arr = [];

    for (var i = 0, max = checkboxes.length; i < max; i += 1) {
      if (checkboxes[i].type === "checkbox" && checkboxes[i].checked) {
        arr.push(parseInt(checkboxes[i].value));
      }
    }
    setChosenExtras(arr);
  }

  function handleUncheck(index) {
    let radio = document.getElementsByName("mealAdditional");

    if (additionalIndex == index && radio[index].checked) {
      radio[index].checked = false;
      setAdditionalIndex(null);
      setTotal(product.sizes[sizeIndex].price);
      setTotalCal(product.sizes[sizeIndex].calories);
      setChosenAddition(null);
    }
  }
  return (
    <div className="cartItem">
      {noSizes ? <MealNotAvail noSizesMsg={noSizesMsg} /> : null}

      {isBusyPopupVisible && (
        <div className="busyPopup">
          <p>{t("branchBusy")}</p>
        </div>
      )}
     
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div>
          {modal ? (
            <PhoneModal
              setHasToken={setHasToken}
              setModal={setModal}
              handleAdd={handleAdd}
            />
          ) : null}
          <div className="ciImage">
            <Link to="/menu/meals">
              {" "}
              <button
                className={languageState.lang == "en"||languageState.lang=="id" ? "ciBackbtn" : null}
              >
                <HiArrowRight size={25} />
              </button>
            </Link>

            {/*  <div className='itemImage' style={{backgroundImage:`url(${product.image})`}}>
     </div>*/}

            <BackgroundImage
              src={product && product.image}
              className="itemImage"
            />
          </div>
          <div className="ciDetails ">
            <div
              className="ciDetails-div "
              style={{ padding: "3em", paddingBottom: "0" }}
            >
              <div className="row">
                <div className="btn col-sm ciTopFlex">
                  <button
                    onClick={() => (quan !== 1 ? setQuan(quan - 1) : null)}
                    className={languageState.lang == "en" ||languageState.lang=="id" ? "enbtn1" : "btn1"}
                  >
                    -
                  </button>
                  <p>{quan}</p>
                  <button
                    onClick={() => setQuan(quan + 1)}
                    className={languageState.lang == "en" ||languageState.lang=="id" ? "enbtn2" : "btn2"}
                  >
                    +
                  </button>
                </div>
                <div className="col-sm alignTop">
                  <p className={languageState.lang == "en" ||languageState.lang=="id" ? "enciP1" : "ciP1"}>
                    {product&&product.name}{" "}
                  </p>
                </div>
                <div className="col-12">
                  <p className={languageState.lang == "en" ||languageState.lang=="id" ? "enciP2" : "ciP2"}>
                    {product&&product.description}{" "}
                  </p>
                </div>

                <div className="btn col-sm ciTopFlex ciTopFlexMobile">
                  <button
                    onClick={() => (quan !== 1 ? setQuan(quan - 1) : null)}
                    className={languageState.lang == "en" ||languageState.lang=="id" ? "enbtn1" : "btn1"}
                  >
                    -
                  </button>
                  <p>{quan}</p>
                  <button
                    onClick={() => setQuan(quan + 1)}
                    className={languageState.lang == "en" ||languageState.lang=="id" ? "enbtn2" : "btn2"}
                  >
                    +
                  </button>
                </div>
              </div>
              <div style={{ marginTop: "5%" }}>
                <Accordion defaultActiveKey={["0", "1", "2"]} flush alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div>
                        <span className="rotate">
                          <HiOutlineChevronDown />
                        </span>{" "}
                        <span>{t("Single_product.sizes")}</span>{" "}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        style={{
                          margin: 0,
                          padding: 0,
                          ...(languageState.lang === "en" ||languageState.lang=="id" ||
                          !additionalsRequired
                            ? { direction: "rtl" }
                            : { direction: "ltr" }),
                        }}
                      >
                        {sizes &&
                          sizes.map((data, index) => {
                            return (
                              <div style={languageState.lang === 'ar' ||i18n.language == "ur" ? { direction: 'ltr' } : languageState.lang === 'en' ? { direction: 'rtl' } : null}
                                className={
                                  languageState.lang === "en" ||languageState.lang=="id"
                                    ? "col-12 flex "
                                    : "col-12 flex enflex"
                                }
                                key={index}
                              >
                                <p style={{ direction: languageState.lang === 'ar' ||i18n.language == "ur" ? 'rtl' : 'ltr' }} >
                                  {data.isAdditionalRequired
                                    ? null
                                    : data.price + " " +
                                      (t('Currency'))}
                                </p>
                                <div className="cstmrChoice" style={{}}>
                                  <label htmlFor={`html${index}`}>
                                    {data.name}{" "}
                                  </label>
                                  <input
                                    onChange={() => handleChange(index)}
                                    type="radio"
                                    id={`html${index}`}
                                    name="option"
                                    value="HTML"
                                    defaultChecked={index === sizeIndex}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {additionals && additionals.length > 0 ? (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div>
                          <span className="rotate">
                            <HiOutlineChevronDown />
                          </span>{" "}
                          <span>{t("Single_product.additions")}</span>{" "}
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div className="row">
                          {additionals &&
                            additionals.map((data, index) => {
                              return (
                                <div
                                  style={
                                    languageState.lang == "en" ||languageState.lang=="id"
                                      ? { direction: "rtl" }
                                      : { direction: "ltr" }
                                  }
                                  className="col-12 flex"
                                  key={index}
                                >
                                  <p
                                    style={
                                      languageState.lang === "en" ||languageState.lang=="id"
                                        ? { direction: "ltr" }
                                        : { direction: "rtl" }
                                    }
                                  >
                                    {data.price}{" "}
                                    {(t('Currency'))}
                                  </p>
                                  <div className="cstmrChoice">
                                    <label
                                      className={
                                        languageState.lang == "en" ||languageState.lang=="id"
                                          ? "enadditional"
                                          : null
                                      }
                                      htmlFor="html"
                                    >
                                      {data.name}{" "}
                                    </label>
                                    <input
                                      className="additions"
                                      onClick={
                                        additionalsRequired
                                          ? null
                                          : () => handleUncheck(index)
                                      }
                                      onChange={(e) =>
                                        calc(data.calories, data.price, index)
                                      }
                                      type="radio"
                                      id="html"
                                      name="mealAdditional"
                                      value={data.arName}
                                      defaultChecked={index === additionalIndex}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                  {!extras.length == 0 ? (
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div>
                          <span className="rotate">
                            <HiOutlineChevronDown />
                          </span>{" "}
                          <span>{t("Single_product.extras")}</span>{" "}
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        {extras &&
                          extras.map((data, index) => {
                            return (
                              <div
                                className={
                                  languageState.lang == "en"||languageState.lang=="id"
                                    ? "encol2 col"
                                    : "col"
                                }
                                key={index}
                              >
                                <label htmlFor={data.enName}>
                                  {data.name}{" "}
                                </label>
                                <input
                                  className="checkBox extras"
                                  onChange={() => handleExtras()}
                                  type="checkbox"
                                  id={data.enName}
                                  name={data.enName}
                                  value={data.id}
                                />
                              </div>
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                </Accordion>
              </div>
            </div>
            <div
              style={
                languageState.lang == "en" ||languageState.lang=="id"
                  ? { paddingLeft: "50px" }
                  : { paddingRight: "50px" }
              }
            >
              {/* !reommend.length==0?
 <div>


   <p className={languageState.lang=="en"?'enBscrollTitle':'BscrollTitle'} > {t("Single_product.recommended")}</p>
   <div  className={languageState.lang=="en"?'enBscrollmenu ':'Bscrollmenu'} >
   
   {reommend.map((data, index)=>{
       return (
       <BasketScroll currency={languageState.lang=="en"?product.enCurrency :product.arCurrency} setQuantity={setQuan} location={location.pathname} key={index} name={languageState.lang=="ar"? data.arName:data.enName}  id={data.id} image={data.image} price={data.price} resetQuantity={setQuan}/>  
       
       );})}

       </div></div>:null*/}
            </div>
            <div
              className="bottomPadding"
              style={{ padding: "3em", paddingTop: "0" }}
            >
              <div
                className={
                  isShowingAlert ? "addAlert" : " addAlert alert-hidden"
                }
              >
                <div className="row">
                  <div className="col">
                    <div
                      className={
                        i18n.language == "ar"||i18n.language == "ur"
                          ? "vAlignText"
                          : "vAlignText enAlert"
                      }
                    >
                      <p>
                        {t("Single_product.added1") + " "}
                        <span>
                          {product&&product.name}
                        </span>
                      </p>
                      <p>{t("Single_product.added2")}</p>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="alertImg"
                      style={{
                        backgroundImage: `url(${product && product.image})`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="ciBottom row">
                <div className="col-lg-3 col-4">
                  {/*<span>{price}</span>*/}
                  <p
                    style={
                      languageState.lang == "ar" ||i18n.language == "ur"
                        ? { direction: "rtl", textAlign: "left" }
                        : { direction: "ltr", textAlign: "right" }
                    }
                    className="ciB-1"
                  >
                    {total * quan}{" "}
                    {(t('Currency'))}{" "}
                  </p>
                </div>

                <div className="col-lg-2 col-8 order-lg-last">
                  <p
                    className={languageState.lang == "en" ||languageState.lang=="id" ? "enciB-2" : "ciB-2"}
                  >
                    {t("Single_product.calories1")}
                  </p>
                  <p
                    style={{ direction: "rtl" }}
                    className={languageState.lang == "en"||languageState.lang=="id" ? "enciB-3" : "ciB-3"}
                  >
                    {totalCal} {t("Single_product.calories2")}
                  </p>
                </div>

                <div className="col-lg-7 center ">
                  {/* disabled={items.sizes[sizeIndex].isAdditionalRequired?
  !(items.sizes[sizeIndex].additionals[additionalIndex].canAddToCart)
  :!items.sizes[sizeIndex].canAddToCart}  */}
                  <button onClick={hasToken ? handleAdd : () => setModal(true)}>
                    {" "}
                    {t("Single_product.button")} <RiShoppingCartFill />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default CartItem;
