import "../Styles/MainPage.css";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import chicken from "../Images/LandingPage.svg";
import chickenEn from "../Images/LandingPageEn.svg";
import logoAr from "../Images/fullLogoAr.svg";
import logoEn from "../Images/fullLogoEn.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineGlobal } from "react-icons/ai";
import BeatLoader from "react-spinners/BeatLoader";
import { setLanguage } from "../store/action/language/language";
import { useDispatch, useSelector } from "react-redux";
import { fetchBasket } from "../store/action/cart/cart";
import { fetchBranchData } from "../store/action/cart/editCart";

function LandingPage() {
  const { t, i18n } = useTranslation();
  const [typId, setTypId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const search = window.location.search;
  var dt = new Date();
  const year = dt.getFullYear();
  const dispatch = useDispatch();
  let history = useNavigate();
  const phoneRef = useRef();
  const { lang, dir } = useSelector((state) => state.language);

  const languageState = useSelector((state) => state.language);
  const { branch, loading } = useSelector((state) => state.cart);
  const [menuVisible, setMenuVisible] = useState(false);

  //get branch id , type id and table id(if exists) from url (to be used in changing display in landing screen and in creating an order)
  //branch_data api call takes branch id ()=> fetch branch data
  //set language, document direction and webpage title (for when user changes language in MainPage screen then returns to this screen so both screens would have same language)
  useEffect(() => {
    i18n.changeLanguage(lang);
    document.getElementById("root").style.direction = dir;
    const token = window.localStorage.getItem("TOKEN");

    if (JSON.parse(token) != null) {
      dispatch(fetchBasket(JSON.parse(token)));
    }

    const params = new URLSearchParams(search);
    const branchId = params.get("branchId");

    let brnchId = 1; // Default if not found
    if (branchId) {
      brnchId = branchId;
    }
    window.localStorage.setItem("branchId", brnchId);

    let typeId = params.get("typeId");

    let type = 1; // Default if not found
    if (typeId) {
      setTypId(typeId);
      type = typeId;
    }
    window.localStorage.setItem("typeId", type);

    let tableId = params.get("tableId");
    if (tableId) {
      setTableId(tableId);
      window.localStorage.setItem("tableId", tableId);
    }

    dispatch(fetchBranchData(brnchId));

    // Handle potential null branch object:
    const names = {
      arName: branch?.arName,
      enName: branch?.enName,
    };
    window.localStorage.setItem("branchName", JSON.stringify(names));

    // Set document title based on i18n language:
    const title =
      i18n.language === "ar" ? "مطاعم الرومانسية" : "Al Romansiah Restaurant";
    document.title = title;
  }, [dispatch]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLanguageChange = (lang, dir) => {
    changeLanguage(lang, dir);
    setMenuVisible(false);
  };
  const menuStyle = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    position: "absolute",
    top: "100%",
    left: 0,
    zIndex: 1000,
  };

  const buttonStyle = {
    border: "none",
    backgroundColor: "#fff",
    cursor: "pointer",
    padding: "10px 20px",
    textAlign: "left",
    width: "100%",
    marginBottom: "5px",
  };

  const lastButtonStyle = {
    ...buttonStyle,
    marginBottom: 0,
  };

  //change language and direction
  const changeLanguage = (language, direction) => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language, direction));
    document.getElementById("root").style.direction = direction;
  };

  return (
    <div className="MainMenu ">
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <div
            className={
              languageState.lang === "ar" || languageState.lang == "ur"
                ? "langSwitch landingLang landingLangAr"
                : "langSwitch landingLang"
            }
          >
            <div
              className="langBtns"
              onClick={toggleMenu}
              style={{ margin: "0", position: "relative" }}
            >
              <AiOutlineGlobal className="globe" size={25} />
              <button className="langBtn">
                {languageState.lang.toUpperCase()}
              </button>
            </div>
            {menuVisible && (
              <div style={menuStyle}>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("en", "rtl")}
                >
                  EN
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("ar", "ltr")}
                >
                  AR
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("id", "rtl")}
                >
                  ID
                </button>
                <button
                  style={buttonStyle}
                  onClick={() => handleLanguageChange("ur", "ltr")}
                >
                  UR
                </button>
              </div>
            )}
          </div>
          <div
            className={
              languageState.lang == "ar" || languageState.lang == "ur"
                ? "landingForm container-fluid"
                : "landingForm landingFormEn container-fluid"
            }
          >
            <div className="">
              <div className="" style={{ position: "relative" }}>
                <img
                  src={
                    i18n.language == "ar" ||
                    i18n.language == "ur" ||
                    i18n.language == "ur"
                      ? chicken
                      : chickenEn
                  }
                  className="lpImage"
                />
              </div>
              <div className="lpAlign" style={{ position: "relative" }}>
                <form
                  style={
                    languageState.lang == "ar" || languageState.lang == "ur"
                      ? { direction: "ltr" }
                      : { direction: "rtl", paddingLeft: "1em" }
                  }
                >
                  <img
                    src={
                      i18n.language == "ar" || i18n.language == "ur"
                        ? logoAr
                        : logoEn
                    }
                    className="lpLogo"
                  />
                  {/*<div  className='logoDiv' style={{ backgroundImage: `url(${i18n.language=="ar"?logoAr:logoEn})` }}>

</div>*/}

                  <p className="welcome">
                    {" "}
                    <span className="brnchName">
                      {i18n.language == "ar" || i18n.language == "ur"
                        ? "!" + branch.arName + " "
                        : "!" + branch.enName + " "}
                    </span>{" "}
                    {t("LandingPage.welcome")}{" "}
                  </p>
                  {typId == 5 ? (
                    <p className="qrType">
                      {t("LandingPage.order")} {t("LandingPage.type2")}{" "}
                    </p>
                  ) : (
                    <p className="qrType">
                      {t("LandingPage.order")} {t("LandingPage.type1")}{" "}
                    </p>
                  )}
                  {typId == 5 ? (
                    <p className="qrType">
                      {t("LandingPage.table")} {tableId}{" "}
                    </p>
                  ) : null}
                  {/*

<input value={formik.values.phone} type="text" id="phone" name="phone" placeholder={t("LandingPage.input")}  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur} style={language.language=="ar"?{textAlign:'right',paddingRight:"15px",direction:"rtl"}:{textAlign:'left',direction:"ltr",paddingLeft:"15px"}}/>
<span>{formik.errors.phone && formik.touched.phone?t("LandingPage.validation"):null}</span>
*/}

                  <div
                    className={
                      i18n.language == "ar" || i18n.language == "ur"
                        ? "lpBtn"
                        : "lpBtn lpBtnEn"
                    }
                  >
                    <Link to="/menu">
                      <button type="button">{t("LandingPage.button")}</button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>{" "}
        </>
      )}

      <div className="footerLink">
        <p>
          {t("LandingPage.powered")}{" "}
          <a
            target="_blank"
            style={
              i18n.language == "ar" || i18n.language == "ur"
                ? { marginRight: "5px" }
                : { marginLeft: "5px" }
            }
            href="https://www.bdaiat.com/"
          >
            {" "}
            {t("LandingPage.bdaiat")}
          </a>
        </p>
        <p>
          {t("LandingPage.copy_write_parg")} <span>© {year} </span>{" "}
        </p>
      </div>
    </div>
  );
}

export default LandingPage;
