import "../Styles/MainPage.css";

import menu from "../Images/MenuIcon.svg";
import cart from "../Images/CartIcon.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBasket } from "../store/action/cart/cart";

function BottomNav(props) {
  const { t, i18n } = useTranslation();
  const bucket = useSelector((state) => state.cart.bucket);
  const { loading } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  console.log(bucket)

  return (
    <div style={{ position: props.style }} className="BottomNav ">
      <NavLink
        to={
          bucket  && bucket.length !== 0
            ? "/cart"
            : "#"
        }
      >
        <div style={{ position: "relative" }}>
          {bucket  && bucket.length !== 0 ? (
            <span className="cartNumber">{bucket.length}</span>
          ) : null}
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_356_6018)">
              <g clipPath="url(#clip1_356_6018)">
                <path
                  d="M6.17761 10.6667H27.7035L28.4757 7.61905H9.26642V4.57143H30.4556C30.6904 4.57143 30.9221 4.62425 31.133 4.72585C31.344 4.82745 31.5287 4.97516 31.6731 5.15777C31.8176 5.34038 31.9179 5.55307 31.9665 5.77967C32.0151 6.00628 32.0108 6.24084 31.9537 6.46552L28.0927 21.7036C28.009 22.0331 27.8162 22.3256 27.5448 22.5346C27.2733 22.7436 26.9389 22.8571 26.5946 22.8571H4.63321C4.22361 22.8571 3.83078 22.6966 3.54115 22.4108C3.25152 22.1251 3.08881 21.7375 3.08881 21.3333V3.04762H0V0H4.63321C5.04281 0 5.43564 0.160544 5.72527 0.446313C6.0149 0.732083 6.17761 1.11967 6.17761 1.52381V10.6667ZM6.17761 32C5.35841 32 4.57276 31.6789 3.9935 31.1074C3.41423 30.5358 3.08881 29.7607 3.08881 28.9524C3.08881 28.1441 3.41423 27.3689 3.9935 26.7974C4.57276 26.2259 5.35841 25.9048 6.17761 25.9048C6.99682 25.9048 7.78247 26.2259 8.36173 26.7974C8.94099 27.3689 9.26642 28.1441 9.26642 28.9524C9.26642 29.7607 8.94099 30.5358 8.36173 31.1074C7.78247 31.6789 6.99682 32 6.17761 32ZM24.7105 32C23.8913 32 23.1056 31.6789 22.5263 31.1074C21.9471 30.5358 21.6216 29.7607 21.6216 28.9524C21.6216 28.1441 21.9471 27.3689 22.5263 26.7974C23.1056 26.2259 23.8913 25.9048 24.7105 25.9048C25.5297 25.9048 26.3153 26.2259 26.8946 26.7974C27.4738 27.3689 27.7993 28.1441 27.7993 28.9524C27.7993 29.7607 27.4738 30.5358 26.8946 31.1074C26.3153 31.6789 25.5297 32 24.7105 32Z"
                  fill="#BDBDBD"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_356_6018">
                <rect width="32" height="32" fill="white" />
              </clipPath>
              <clipPath id="clip1_356_6018">
                <rect
                  width="35.5556"
                  height="35.5556"
                  fill="white"
                  transform="translate(-1.77778 -1.77777)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>{t("Bottom_Nav.basket")}</p>
        </div>
      </NavLink>

      <NavLink to="/menu/meals">
        <div style={{ position: "relative" }}>
          <svg
            className="Svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0001 9.375C14.4492 9.375 13.1876 10.6366 13.1876 12.1875C13.1876 12.6087 13.2872 13.0048 13.4538 13.3638C7.21573 14.5118 2.35167 19.9141 1.92261 26.375H30.0776C29.6485 19.9141 24.7845 14.5118 18.5464 13.3638C18.713 13.0048 18.8126 12.6087 18.8126 12.1875C18.8126 10.6366 17.551 9.375 16.0001 9.375Z"
              fill="#BDBDBD"
            />
            <path
              d="M21.625 1.875H30.0625V0H21.625C20.0741 0 18.8125 1.26163 18.8125 2.8125C18.8125 4.36337 20.0741 5.625 21.625 5.625H25.375C25.8923 5.625 26.3125 6.04525 26.3125 6.5625C26.3125 7.07975 25.8923 7.5 25.375 7.5H23.5C21.9491 7.5 20.6875 8.76163 20.6875 10.3125V11.25H22.5625V10.3125C22.5625 9.79525 22.9827 9.375 23.5 9.375H25.375C26.9259 9.375 28.1875 8.11337 28.1875 6.5625C28.1875 5.01163 26.9259 3.75 25.375 3.75H21.625C21.1077 3.75 20.6875 3.32975 20.6875 2.8125C20.6875 2.29525 21.1077 1.875 21.625 1.875Z"
              fill="#BDBDBD"
            />
            <path
              d="M0 30.125H3.1705L4.108 32H27.892L28.8295 30.125H32V28.25H0V30.125Z"
              fill="#BDBDBD"
            />
          </svg>

          <p> {t("Bottom_Nav.menu")}</p>
        </div>
      </NavLink>
    </div>
  );
}

export default BottomNav;
