import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import success from "../Images/success.svg";
import failed from "../Images/faild.svg";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

import BeatLoader from "react-spinners/BeatLoader";

import { useNavigate } from "react-router-dom";
import { payment, pay_again } from "../store/action/order/order";

function PaymentSuccess() {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const [state, setState] = useState(false);
  const [orderOption, setOrderOption] = useState(false);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const { payId } = useParams();
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const orderId = parseInt(params.get("TrackId"));
  const TranId = params.get("TranId");
  let history = useNavigate();
  const language = useSelector((state)=> state.language.lang)


  //payment api call checks if operation was succeccfull
  // display is changed accordingly failure of operation
  useEffect(() => {
    i18n.changeLanguage(language);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let tapId = params.get("tap_id");

    if (tapId !== "") {
      payment(tapId, localStorage.getItem("TOKEN"))
        .then((response) => {
          setState(true);
          setLoading(false);
        })
        .catch(function (error) {
          setState(false);
          setLoading(false);
        });
    } else {
      setState(false);
      setLoading(false);
    }
  }, []);

  //if operation failed and user clicks on pay again
  //pay_again api call takes order id (stored in Basket component on confirming order) and redirects user to third party service
  function payAgain() {
    setPaymentLoad(true);
    let values = {
      transactionId: TranId,
    };
    pay_again(localStorage.getItem("orderId"), localStorage.getItem("TOKEN"))
      .then((response) => {
        window.location.href = response.data.data.url;
      })
      .catch(function (error) {
        // console.log(error.response)
        setPaymentLoad(false);
      });
  }

  const handleInvoice = () => {
    const id = `${localStorage.getItem("orderId")}-${localStorage
      .getItem("orderQueueId")
      .substring(1)}`;
    history(generatePath("/invoice/:id", { id }));
  };

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="container" style={{ height: "100vh" }}>
          <div className="orderRec" style={{ paddingTop: "2em" }}>
            {state ? (
              <div className="shareForm ">
                <div>
                  <img style={{ marginBottom: "1em" }} src={success} />
                </div>
                <p style={{ color: "rgb(99, 50, 53)", display: "block" }}>
                  {t("payment.success_payment")}
                </p>
                <p
                  style={
                    language == "ar" ||language == "ur"
                      ? {
                          direction: "rtl",
                          fontWeight: "600",
                          marginBottom: "0",
                          display: "block",
                        }
                      : {
                          direction: "ltr",
                          fontWeight: "600",
                          marginBottom: "0",
                          display: "block",
                        }
                  }
                >
                  {t("Basket.orderQueueId")} :&nbsp;{" "}
                  {localStorage.getItem("orderQueueId")}
                </p>

                <p
                  style={{
                    fontWeight: "600",
                    display: "block",
                    marginBottom: "2em",
                  }}
                >
                  {t("Basket.orderId")} :&nbsp;{" "}
                  {localStorage.getItem("orderId")}
                </p>

                <div
                  className={
                    language == "ar" ||language == "ur" ? "orderBtns" : "orderBtnsEn"
                  }
                >
                  <Link to="/menu/meals">
                    <button>{t("Basket.orderBtn")}</button>
                  </Link>
                  <button className="invoiceBtn" onClick={handleInvoice}>
                    {t("Basket.invoice")}
                  </button>
                </div>
              </div>
            ) : (
              <div className="shareForm ">
                <div>
                  <img style={{ marginBottom: "1em" }} src={failed} />
                </div>
                <p style={{ color: "rgb(99, 50, 53)", display: "block" }}>
                  {t("payment.notSuccess_payment")}
                </p>

                {paymentLoad ? (
                  <div style={{ marginTop: "50px" }} className="loaderDiv">
                    <BeatLoader
                      color={"#E47100"}
                      loading={paymentLoad}
                      size={30}
                    />
                  </div>
                ) : (
                  <button onClick={payAgain}> {t("payment.pay_again")}</button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentSuccess;
