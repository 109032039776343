import notFound from "../Images/404.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function NotFound() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="fountContainer">
        <div
          className="shareForm foundUrl"
          style={{ backgroundImage: `url(${notFound})` }}
        >
          <p>{t("home.notFound")}</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
