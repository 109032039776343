export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';



export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';



export const FETCH_RECOMMENDED_PRODUCTS_REQUEST = 'FETCH_RECOMMENDED_PRODUCTS_REQUEST';
export const FETCH_RECOMMENDED_PRODUCTS_SUCCESS = 'FETCH_RECOMMENDED_PRODUCTS_SUCCESS';
export const FETCH_RECOMMENDED_PRODUCTS_FAILURE = 'FETCH_RECOMMENDED_PRODUCTS_FAILURE';


export const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';



export const FETCH_BASKET_REQUEST = 'FETCH_BASKET_REQUEST';
export const FETCH_BASKET_SUCCESS = 'FETCH_BASKET_SUCCESS';
export const FETCH_BASKET_FAILURE = 'FETCH_BASKET_FAILURE';

export const DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';



export const CLEAR_CART_REQUEST = 'CLEAR_CART_REQUEST';
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAILURE = 'CLEAR_CART_FAILURE';



export const EDIT_QUANTITY_REQUEST = 'EDIT_QUANTITY_REQUEST';
export const EDIT_QUANTITY_SUCCESS = 'EDIT_QUANTITY_SUCCESS';
export const EDIT_QUANTITY_FAILURE = 'EDIT_QUANTITY_FAILURE';


export const FETCH_BRANCH_REQUEST = 'FETCH_BRANCH_REQUEST';
export const FETCH_BRANCH_SUCCESS = 'FETCH_BRANCH_SUCCESS';
export const FETCH_BRANCH_FAILURE = 'FETCH_BRANCH_FAILURE';

export const ORDER_CREATION_SUCCESS = 'ORDER_CREATION_SUCCESS';
