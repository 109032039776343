
import i18n from 'i18next'
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationAr from "./Locales/ar/translation.json"
import translationEn from "./Locales/en/translation.json"
import translationId from "./Locales/id/translation.json"
import translationUr from "./Locales/ur/translation.json"
const Languages = ['ar','en', 'id', 'ur'];


const resources = {
  en: {
    translation: translationEn
  },
  ar: {
    translation: translationAr
  },
  ur: {
    translation:translationUr
  },
  id: {
    translation:translationId
  }
};
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: 'ar',
  debug: false,
  lng: 'ar',
  whitelist: Languages,
  
  interpolation: {
    escapeValue: false
  }
})

export default i18n;