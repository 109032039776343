import React, { useEffect, useState, useRef } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import BeatLoader from "react-spinners/BeatLoader";
import { Get_Token } from "../../src/store/action/cart/editCart";
import { useSelector } from "react-redux";

function PhoneModal(props) {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const phoneRef = useRef();
  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
  const branchId = parseInt(localStorage.getItem("branchId"));
  console.log(branchId);
  const phoneForBranch38 = "0500000006";

  const {lang, dir} = useSelector((state)=> state.language)
  console.log(lang)
 

  const phoneSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(
        /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/,
        {
          message: t("LandingPage.validation"),
          excludeEmptyString: true,
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: phoneSchema,
    onSubmit: (values) => {
      setLoading(true);
      let phone = values.phone.trim();

      if (branchId === 39 && !phone) {
        phone = phoneForBranch38;
        localStorage.setItem("phoneNumber",phoneForBranch38 )
      }else {
        localStorage.setItem("phoneNumber",phone )
      }

      Get_Token(phone)
        .then((response) => {
          console.log(response)
          localStorage.setItem(
            "TOKEN",
            JSON.stringify(response.data.arrResponse.data.access_token)
          );
          
          props.setHasToken(true);
          props.setModal(false);
          props.handleAdd();
        })
        .catch((error) => {
          console.error("Error fetching token:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  console.log(typeof(i18n.language))


  return (
    <div>
      <div className="Modal">
        <div
          onClick={() => props.setModal(false)}
          className="modalOverlay"
        ></div>
        <div
          className={
            lang == "ar"
              ? "modal-content"
              : "modal-content modal-contentEn "
          }
          style={
            lang == "ar"
              ? {
                  width: "50vw",
                  maxWidth: "400px",
                  minWidth: "330px",
                  left: "50vw",
                  backgroundColor: "#FFFFFF",
                }
              : {
                  width: "90%",
                  maxWidth: "400px",
                  minWidth: "330px",
                  backgroundColor: "#FFFFFF",
                }
          }
        >
          <div>
            {loading ? (
              <div className="loader" style={{ height: "220px" }}>
                <BeatLoader color={"#E47100"} loading={loading} size={30} />
              </div>
            ) : (
              <div
                style={{ textAlign: "center", padding: "1em" }}
                className="modalBody"
              >
                <p
                  className="offer"
                  style={
                    lang == "ar"
                      ? { direction: "rtl", fontWeight: "600" }
                      : { direction: "ltr", fontWeight: "600" }
                  }
                >
                  {" "}
                  {t("LandingPage.text")}
                </p>

                <form onSubmit={formik.handleSubmit} className="phoneForm">
                  <input
                    value={formik.values.phone}
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder={`${t("LandingPage.input")} ${
                      branchId === 39 ? `(${t("LandingPage.optional")})` : ""
                    }`}
                    ref={phoneRef}
                    required={branchId != 39}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={
                      lang == "ar"
                        ? {
                            textAlign: "right",
                            paddingRight: "15px",
                            direction: "rtl",
                          }
                        : {
                            textAlign: "left",
                            direction: "ltr",
                            paddingLeft: "15px",
                          }
                    }
                  />
                  <span>
                    {formik.errors.phone &&
                    formik.touched.phone &&
                    branchId !== 39
                      ? t("LandingPage.validation")
                      : null}
                  </span>

                  <div className="modalOption">
                    <button type="submit" className="confirm">
                      {" "}
                      {t("Basket.confirm")}
                    </button>
                    <button
                      type= {branchId === 39 ? "submit" : "button"}
                      onClick={ branchId ===39 ? null : () => props.setModal(false)}
                      className="cancel"
                    >
                      {" "}
                     { branchId ===39 ? t("Basket.skip"):  t("Basket.cancel") }
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
          <button className="close-modal" onClick={() => props.setModal(false)}>
            <IoCloseSharp size={40} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default PhoneModal;

