import * as actionType from '../../action/language/actionType'



const initialState = {
    lang:'ar',
    dir:'ltr'
}



const languageReducer = (state=initialState, action)=> {
    switch(action.type) {
        case actionType.SET_LANGUAGE: return {...state, lang:action.payload.lang, dir:action.payload.dir}
        default:
            return state;

    }
}


export default languageReducer