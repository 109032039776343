import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from "react-i18next";

function ConfirmDelete(props) {
  const { t, i18n } = useTranslation();

  //on confirimg clear cart
  //calls clearCart function in Basket component
  function handleDel() {
    props.clearCart();

    props.setModal(false);
    props.setLoading(true);
  }

  return (
    <div>
      <div   className="Modal">
        <div
          onClick={() => props.setModal(false)}
          className="modalOverlay"
        ></div>
        <div
          className={
            i18n.language == "ar" || i18n.language == "ur"
              ? "modal-content"
              : " modal-content modal-contentEn "
          }
          style={
            i18n.language == "ar" || i18n.language == "ur"
              ? {
                  width: "50vw",
                  maxWidth: "400px",
                  minWidth: "330px",
                  left: "50%",
                  backgroundColor: "#FFFFFF",
                }
              : { width: "90%", maxWidth: "400px", minWidth: "330px", backgroundColor: "#FFFFFF", }
          }
        >
          <div>
            <div
              style={{ textAlign: "center", padding: "1em" }}
              className="modalBody"
            >
              <p
                className="offer"
                style={
                  i18n.language == "ar" || i18n.language == "ur"
                    ? { direction: "rtl" }
                    : { direction: "ltr" }
                }
              >
                {" "}
                {t("Basket.delete")}
              </p>

              <div className="modalOption">
                <button className="confirm" onClick={handleDel}>
                  {" "}
                  {t("Basket.confirm")}
                </button>
                <button
                  onClick={() => props.setModal(false)}
                  className="cancel"
                >
                  {" "}
                  {t("Basket.cancel")}
                </button>
              </div>
            </div>
          </div>
          <button className="close-modal" onClick={() => props.setModal(false)}>
            <IoCloseSharp size={40} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDelete;
