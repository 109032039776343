import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import disc from "../Images/ic_percent2.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { create_order } from "../store/action/order/order";
import { useDispatch, useSelector } from "react-redux";

function OfferModal(props) {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch()

  const free = JSON.parse(
    window.localStorage.getItem("FREEMEALS")
      ? window.localStorage.getItem("FREEMEALS")
      : "[1]"
  );
  const totalMeals = free[0].quantity * free.length;

  //on pressing continue create_order api is called taking data2 as body *stored in Bakset component*
  //if payment method is tap user is redirected to third party service else, user is redirected to /order-recieved
  function handleContinue() {
    setDisabled(true);
    const token = window.localStorage.getItem("TOKEN");
    const cartId = window.localStorage.getItem("CartId");
    const data2 = window.localStorage.getItem("ORDER");
    create_order(JSON.parse(data2), JSON.parse(token), dispatch)
      .then((response) => {
        if (JSON.parse(data2).payment === "tap") {
          localStorage.setItem("orderId", response.data.data.orderId);
          localStorage.setItem("orderQueueId", response.data.data.orderQueueId);

          if (response.data.data.url === null) {
            //localStorage.setItem("menuRoute", "false");
          } else {
            window.location.href = response.data.data.url;
          }
        } else if (JSON.parse(data2).payment === "checkout") {
          localStorage.removeItem("cardToken");
          localStorage.setItem("orderId", response.data.data.orderId);
          localStorage.setItem("orderQueueId", response.data.data.orderQueueId);

          if (
            response.data.data.url === null ||
            response.data.data.url === "" ||
            response.data.data.url == undefined
          ) {
            history("/order-recieved");
          } else {
            window.location.href = response.data.data.url;
          }
        } else {
          localStorage.setItem("orderId", response.data.data.orderId);
          localStorage.setItem("orderQueueId", response.data.data.orderQueueId);

          history("/order-recieved");
        }
      })
      .catch((error) => {
        setDisabled(false);

        if (error.response.status === 422) {
          setErrorMsgModel(true);
          setMsgEn("Please wait, there is a request under Basket");
          setMsgAr("الرجاء الانتظار ، هناك طلب قيد التنفيذ");
        } else if (
          error.response.data.error ===
          "Transaction has been failed,please try again"
        ) {
          localStorage.removeItem("cardToken");
          localStorage.setItem("orderId", error.response.data.data.orderId);
          setErrorMsgModel(true);
          setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");

          setTimeout(function () {
            history("/failed-payment");
          }, 2000);
        }
      });
  }

  return (
    <div className="Modal">
      <div onClick={() => props.setModal(false)} className="modalOverlay"></div>
      <div
        className={
          i18n.language == "ar" || i18n.language == "ur"
            ? "modal-content"
            : " modal-content modal-contentEn "
        }
        style={
          i18n.language == "ar" || i18n.language == "ur"
            ? {
                width: "50vw",
                maxWidth: "400px",
                minWidth: "300px",
                left: "50%",
                backgroundColor: "#FFFFFF",
              }
            : { width: "90%", maxWidth: "400px", minWidth: "300px" , backgroundColor: "#FFFFFF",}
        }
      >
        <div>
          <div className="offerIcon">
            <div
              className="odderBckg"
              style={{ backgroundImage: `url(${disc})` }}
            ></div>
          </div>
          <div
            style={{ textAlign: "center", padding: "1em" }}
            className="modalBody"
          >
            <p
              className="offer"
              style={
                i18n.language == "ar" || i18n.language == "ur"
                  ? { direction: "rtl" }
                  : { direction: "ltr" }
              }
            >
              {" "}
              {t("Basket.offer")}{" "}
              {localStorage.getItem("offerType") == "discountCashBack"
                ? t("Basket.and") + t("Basket.cashBack")
                : null}{" "}
              {localStorage.getItem("offerType") == "freeMealsDiscount"
                ? t("Basket.and") +
                  `${
                    totalMeals == 1
                      ? `${t("Basket.gift2-1")}`
                      : totalMeals == 2
                      ? `${t("Basket.gift2-2")}`
                      : totalMeals <= 10
                      ? `${totalMeals} ${t("Basket.gift2-3")}`
                      : `${totalMeals} ${t("Basket.gift2-4")}`
                  }`
                : null}
              !
            </p>
            <div
              className="offerdetail"
              style={
                localStorage.getItem("offerType") == "freeMealsDiscount"
                  ? { marginBottom: "0" }
                  : null
              }
            >
              <p
                className="offerBefore"
                style={
                  i18n.language == "ar" || i18n.language == "ur"
                    ? { direction: "rtl" }
                    : { direction: "ltr" }
                }
              >
                {props.price}
                {t("Currency")}
              </p>

              <p className="offer-middle">{t("Basket.instead")}</p>

              <p
                className="offerAfter"
                style={
                  i18n.language == "ar" || i18n.language == "ur"
                    ? { direction: "rtl" }
                    : { direction: "ltr" }
                }
              >
                {props.totalPrice}
                {t("Currency")}
              </p>
            </div>

            {localStorage.getItem("offerType") == "freeMealsDiscount" ? (
              <div className="freeList">
                <ul>
                  {free &&
                    free.map((data, index) => {
                      return (
                        <li key={index}>
                          <span style={{ color: "#E47100" }}>
                            {i18n.language == "ar" || i18n.language == "ur"
                              ? `x${data.quantity}`
                              : `${data.quantity}x`}
                          </span>{" "}
                          &nbsp;{" "}
                          {i18n.language == "ar" || i18n.language == "ur" ? data.arName : data.enName}
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : null}

            <div className="modalBtns">
              {errorMsgModel ? (
                <span style={{ display: "block" }} className="err">
                  {i18n.language == "en" ? msgEn : msgAr}
                </span>
              ) : null}
              <button
                className="confirm continue"
                disabled={disabled}
                onClick={handleContinue}
              >
                {" "}
                {t("Basket.button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferModal;
