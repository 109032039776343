import * as actionType from '../../action/cart/actionType';



const initialState = {
    product : null,
    error: null,
    loading:true,
    cart: [],
    recommendProducts: [],
    paymentMethods: null,
    paymentMethodLoad: true,
    bucket: [],
    branch: null,
    
    updateCart:false

}

const reducer = (state=initialState, action) => {

    switch(action.type) {
        case actionType.FETCH_PRODUCT_REQUEST:
            return {
                ...state, 
                loading:true,
                error:null
            };


        case actionType.FETCH_PRODUCT_SUCCESS:
            return {
                    ...state, 
                    loading:false,
                    product: action.payload
            };

        case actionType.FETCH_PRODUCT_FAILURE:
            return {
                    ...state, 
                    loading:false,
                    error:action.error
                };
        
        case actionType.ADD_TO_CART_REQUEST:
                    return {
                        ...state,
                        loading: true,
                        error: null
                    };
        
        case actionType.ADD_TO_CART_SUCCESS:
                return {
                        ...state,
                        loading: false,
                        cart: [...state.cart, action.payload], 
                        error: null
                    };
        
        case actionType.ADD_TO_CART_FAILURE:
                return {
                        ...state,
                        loading: false,
                        error: action.error 
                    };
        case actionType.FETCH_RECOMMENDED_PRODUCTS_REQUEST:
                        return {
                            ...state,
                            loading: true,
                            error: null
                        };
                    
        case actionType.FETCH_RECOMMENDED_PRODUCTS_SUCCESS:
                        return {
                            ...state,
                            loading: false,
                            recommendProducts: action.payload, 
                            error: null
                        };
                    
        case actionType.FETCH_RECOMMENDED_PRODUCTS_FAILURE:
                        return {
                            ...state,
                            loading: false,
                            error: action.error
                        };
        case actionType.FETCH_PAYMENT_METHODS_REQUEST:
                            return {
                                ...state,
                                paymentMethodLoad: true,
                                error: null,
                            };
                
        case actionType.FETCH_PAYMENT_METHODS_SUCCESS:
                            return {
                                ...state,
                                paymentMethodLoad: false,
                                paymentMethods: action.payload,
                                error: null,
                            };
                
        case actionType.FETCH_PAYMENT_METHODS_FAILURE:
                            return {
                                ...state,
                                paymentMethodLoad: false,
                                error: action.error,
                            };
        case actionType.FETCH_BASKET_REQUEST:
                                return {
                                    ...state,
                                    loading: true,
                                    error: null,
                                };
                            
        case actionType.FETCH_BASKET_SUCCESS:
                                return {
                                    ...state,
                                    loading: false,
                                    bucket: action.payload,
                                    error: null,
                                };
                            
        case actionType.FETCH_BASKET_FAILURE:
                                return {
                                    ...state,
                                    loading: false,
                                    error: action.error,
                                };
        
        
        case actionType.DELETE_ITEM_REQUEST:
            return {
                ...state, 
                loading:true,
            }
        case actionType.DELETE_ITEM_SUCCESS:
            return {
                ...state, 
                
            }
        case actionType.DELETE_ITEM_FAILURE:
            return {
                ...state, 
                loading:false,
                error:action.error

            }
        case actionType.CLEAR_CART_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
        case actionType.CLEAR_CART_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    bucket: null

                };
        case actionType.CLEAR_CART_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };

        case actionType.EDIT_QUANTITY_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
        case actionType.EDIT_QUANTITY_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    updateCart:action.payload

                };
        case actionType.EDIT_QUANTITY_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
    
        case actionType.FETCH_BRANCH_REQUEST:
                    return {
                        ...state,
                        loading: true,
                    };
        case actionType.FETCH_BRANCH_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        branch: action.payload

    
                    };
        case actionType.FETCH_BRANCH_FAILURE:
                    return {
                        ...state,
                        loading: false,
                        error: action.error
                    };
        case actionType.ORDER_CREATION_SUCCESS:{
            
            return {
                ...state,
                loading:false,
                bucket: null,
                cart:null
            }
        }
    
                
        default:
            return state
    }


}

export default reducer